import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';

// lazy load all the views
// auth
// const Login = React.lazy(() => import('../pages/auth/Login'));
// const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
// const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
// const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// const Logout = React.lazy(() => import('../pages/auth/Logout'));

// home
const Home = React.lazy(() => import('../pages/landings/Saas/Saas2'));
// const Home = React.lazy(() => import('../pages/Home'));

// landings
const Saas = React.lazy(() => import('../pages/landings/Saas/Saas'));

// pages
const Dashboard = React.lazy(() => import('../pages/other/account/Dashboard'));
const Settings = React.lazy(() => import('../pages/other/account/Settings'));

const Contact = React.lazy(() => import('../pages/other/Contact'));

const OtherContact = React.lazy(() => import('../pages/other/OtherContact'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <LoadComponent component={Home} />,
        },
        {
            // public routes
            path: '/',
            children: [
                // {
                //     path: 'auth',
                //     children: [
                //         { path: 'login', element: <LoadComponent component={Login} /> },
                //         { path: 'signup', element: <LoadComponent component={SignUp} /> },
                //         { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                //         { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                //         { path: 'logout', element: <LoadComponent component={Logout} /> },
                //     ],
                // },
                {
                    path: 'contact',
                    element: <LoadComponent component={Contact} />
                },
                {
                    path: 'contact_us',
                    element: <LoadComponent component={OtherContact} />
                },
                // {
                //     path: 'docs',
                //     children: [
                //         { path: 'introduction', element: <LoadComponent component={Introduction} /> },
                //         { path: 'quick-start', element: <LoadComponent component={QuickStart} /> },
                //         { path: 'customization', element: <LoadComponent component={Customization} /> },
                //         { path: 'routing', element: <LoadComponent component={Routing} /> },
                //         { path: 'code-spliting', element: <LoadComponent component={CodeSpliting} /> },
                //         { path: 'change-log', element: <LoadComponent component={ChangeLog} /> },

                //         { path: 'colors', element: <LoadComponent component={Colors} /> },
                //         { path: 'typography', element: <LoadComponent component={Typography} /> },
                //         { path: 'bootstrap', element: <LoadComponent component={Bootstrap} /> },
                //         { path: 'custom', element: <LoadComponent component={Custom} /> },
                //         { path: 'plugins', element: <LoadComponent component={Plugins} /> },
                //         { path: 'navbars', element: <LoadComponent component={Navbars} /> },
                //         { path: 'heros', element: <LoadComponent component={Heros} /> },
                //     ],
                // },
                {
                    path: '/home',
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: 'hospital_solution',
                    element: <LoadComponent component={Saas} />,
                },

                // {
                //     path: 'landing',
                //     children: [
                //         { path: 'app', element: <LoadComponent component={App} /> },
                //         { path: 'saas', element: <LoadComponent component={Saas} /> },
                //         { path: 'saas2', element: <LoadComponent component={Saas2} /> },
                //         { path: 'startup', element: <LoadComponent component={Startup} /> },
                //         { path: 'software', element: <LoadComponent component={Software} /> },

                //         { path: 'agency', element: <LoadComponent component={Agency} /> },
                //         { path: 'coworking', element: <LoadComponent component={Coworking} /> },
                //         { path: 'crypto', element: <LoadComponent component={Crypto} /> },
                //         { path: 'marketing', element: <LoadComponent component={Marketing} /> },
                //         { path: 'portfolio', element: <LoadComponent component={Portfolio} /> },
                //     ],
                // },
                // {
                //     path: 'pages',
                //     children: [
                //         {
                //             path: 'blog',
                //             children: [
                //                 { path: '', element: <LoadComponent component={Blog} /> },
                //                 { path: 'post', element: <LoadComponent component={BlogPost} /> },
                //             ],
                //         },
                //         { path: 'company', element: <LoadComponent component={Company} /> },
                //         { path: 'career', element: <LoadComponent component={Career} /> },
                //         { path: 'pricing', element: <LoadComponent component={Pricing} /> },
                //         {
                //             path: 'portfolio',
                //             children: [
                //                 { path: 'grid', element: <LoadComponent component={PortfolioGrid} /> },
                //                 { path: 'masonry', element: <LoadComponent component={PortfolioMasonry} /> },
                //                 { path: 'item', element: <LoadComponent component={PortfolioItem} /> },
                //             ],
                //         },
                //         { path: 'help', element: <LoadComponent component={HelpDesk} /> },
                //     ]
                // },
            ],
        },
        // {
        //     // protected routes
        //     path: '/',
        //     element: <PrivateRoute roles={'Admin'} />,
        //     children: [
        //         {
        //             path: 'pages',
        //             children: [
        //                 {
        //                     path: 'account',
        //                     children: [
        //                         { path: 'dashboard', element: <LoadComponent component={Dashboard} /> },
        //                         { path: 'settings', element: <LoadComponent component={Settings} /> },
        //                     ],
        //                 },
        //             ],
        //         },
        //     ],
        // },
    ]);
};

export default AllRoutes;
